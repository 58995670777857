import './styles.scss'

import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import apiAdapter from 'Services/apiAdapter'
import swal from 'sweetalert'

const OnboardVenue = ({ isEditMode, initialData = null }) => {
  const [formData, setFormData] = useState({
    adminFirstName: '',
    adminLastName: '',
    adminPassword: '',
    adminEmail: '',
    adminMobileNo: '',
    adminGender: '',
    userRoleId: '',
    eventName: '',
    eventPhone: '',
    eventEmail: '',
    eventStartTime: '',
    eventStatus: 'Active',
    locLatitude: '',
    locLongitude: '',
    eventAddress: '',
    eventPostalCode: '',
    city: '',
    state: '',
    eventImage: '',
    eventEndTime: '',
    country: '',
    eventTimezone: '',
  })

  const [errors, setErrors] = useState({})

  useEffect(() => {
    if (initialData) {
      setFormData(initialData)
    }
  }, [initialData])

  const handleChange = (event) => {
    const { name, value } = event.target

    setFormData({ ...formData, [name]: value })

    // Clear errors on change
    setErrors({ ...errors, [name]: '' })
  }

  const handleBlur = (event) => {
    const { name, value } = event.target

    // Validate on blur
    let errorMsg = ''

    if (!value) {
      errorMsg = 'Required'
    } else {
      switch (name) {
        case 'adminEmail':
        case 'eventEmail':
          if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
            errorMsg = 'Invalid email address'
          }
          break
        case 'locLatitude':
        case 'locLongitude':
          if (Number.isNaN(value)) {
            errorMsg = 'Must be a number'
          }
          break
        default:
          break
      }
    }

    setErrors({ ...errors, [name]: errorMsg })
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    // Validate all fields before submit
    const newErrors = {}

    Object.keys(formData).forEach((key) => {
      if (!formData[key]) {
        newErrors[key] = 'Required'
      }
    })

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors)

      return
    }

    try {
      let response

      if (isEditMode) {
        response = await apiAdapter.updateVenue(formData)
        swal('Success', 'Venue updated successfully!', 'success')
      } else {
        response = await apiAdapter.createVenue(formData)
        swal('Success', 'Venue created successfully!', 'success')
      }
    } catch (error) {
      console.error('Error occurred:', error)
      swal(
        'Error',
        'An error occurred while processing your request.',
        'error',
      )
    }
  }

  return (
    <Box className="page-container">
      <div className="page-header">
        <Typography variant="h6">
          {isEditMode ? 'Edit Venue' : 'Onboard Venue'}
        </Typography>
      </div>
      <div className="page-content">
        <form onSubmit={handleSubmit}>
          <div className="form-row">
            <div className="form-group">
              <TextField
                className="input-field"
                label="Admin First Name"
                name="adminFirstName"
                value={formData.adminFirstName}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!errors.adminFirstName}
                helperText={errors.adminFirstName}
                variant="outlined"
                margin="normal"
                fullWidth
              />
            </div>
            <div className="form-group">
              <TextField
                className="input-field"
                label="Admin Last Name"
                name="adminLastName"
                value={formData.adminLastName}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!errors.adminLastName}
                helperText={errors.adminLastName}
                variant="outlined"
                margin="normal"
                fullWidth
              />
            </div>
            <div className="form-group">
              <TextField
                className="input-field"
                label="Admin Password"
                name="adminPassword"
                type="password"
                value={formData.adminPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!errors.adminPassword}
                helperText={errors.adminPassword}
                variant="outlined"
                margin="normal"
                fullWidth
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <TextField
                className="input-field"
                label="Admin Email"
                name="adminEmail"
                type="email"
                value={formData.adminEmail}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!errors.adminEmail}
                helperText={errors.adminEmail}
                variant="outlined"
                margin="normal"
                fullWidth
              />
            </div>
            <div className="form-group">
              <TextField
                className="input-field"
                label="Admin Mobile Number"
                name="adminMobileNo"
                value={formData.adminMobileNo}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!errors.adminMobileNo}
                helperText={errors.adminMobileNo}
                variant="outlined"
                margin="normal"
                fullWidth
              />
            </div>
            <div className="form-group">
              <TextField
                className="input-field"
                label="Admin Gender"
                name="adminGender"
                value={formData.adminGender}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!errors.adminGender}
                helperText={errors.adminGender}
                variant="outlined"
                margin="normal"
                fullWidth
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <TextField
                className="input-field"
                label="User Role ID"
                name="userRoleId"
                value={formData.userRoleId}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!errors.userRoleId}
                helperText={errors.userRoleId}
                variant="outlined"
                margin="normal"
                fullWidth
              />
            </div>
            <div className="form-group">
              <TextField
                className="input-field"
                label="Event Name"
                name="eventName"
                value={formData.eventName}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!errors.eventName}
                helperText={errors.eventName}
                variant="outlined"
                margin="normal"
                fullWidth
              />
            </div>
            <div className="form-group">
              <TextField
                className="input-field"
                label="Event Phone"
                name="eventPhone"
                value={formData.eventPhone}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!errors.eventPhone}
                helperText={errors.eventPhone}
                variant="outlined"
                margin="normal"
                fullWidth
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <TextField
                className="input-field"
                label="Event Email"
                name="eventEmail"
                type="email"
                value={formData.eventEmail}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!errors.eventEmail}
                helperText={errors.eventEmail}
                variant="outlined"
                margin="normal"
                fullWidth
              />
            </div>
            <div className="form-group">
              <TextField
                className="input-field"
                label="Event Start Time"
                name="eventStartTime"
                type="time"
                value={formData.eventStartTime}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!errors.eventStartTime}
                helperText={errors.eventStartTime}
                variant="outlined"
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            </div>
            <div className="form-group">
              <FormControl
                variant="outlined"
                fullWidth
                margin="normal"
                error={!!errors.eventStatus}
              >
                <InputLabel>Event Status</InputLabel>
                <Select
                  className="dropdown"
                  name="eventStatus"
                  value={formData.eventStatus}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label="Event Status"
                >
                  <MenuItem value="Active">Active</MenuItem>
                  <MenuItem value="Inactive">Inactive</MenuItem>
                </Select>
                {errors.eventStatus && (
                  <FormHelperText>{errors.eventStatus}</FormHelperText>
                )}
              </FormControl>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <TextField
                className="input-field"
                label="Latitude"
                name="locLatitude"
                value={formData.locLatitude}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!errors.locLatitude}
                helperText={errors.locLatitude}
                variant="outlined"
                margin="normal"
                fullWidth
              />
            </div>
            <div className="form-group">
              <TextField
                className="input-field"
                label="Longitude"
                name="locLongitude"
                value={formData.locLongitude}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!errors.locLongitude}
                helperText={errors.locLongitude}
                variant="outlined"
                margin="normal"
                fullWidth
              />
            </div>
            <div className="form-group">
              <TextField
                className="input-field"
                label="Event Address"
                name="eventAddress"
                value={formData.eventAddress}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!errors.eventAddress}
                helperText={errors.eventAddress}
                variant="outlined"
                margin="normal"
                fullWidth
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <TextField
                className="input-field"
                label="Postal Code"
                name="eventPostalCode"
                value={formData.eventPostalCode}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!errors.eventPostalCode}
                helperText={errors.eventPostalCode}
                variant="outlined"
                margin="normal"
                fullWidth
              />
            </div>
            <div className="form-group">
              <TextField
                className="input-field"
                label="City"
                name="city"
                value={formData.city}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!errors.city}
                helperText={errors.city}
                variant="outlined"
                margin="normal"
                fullWidth
              />
            </div>
            <div className="form-group">
              <TextField
                className="input-field"
                label="State"
                name="state"
                value={formData.state}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!errors.state}
                helperText={errors.state}
                variant="outlined"
                margin="normal"
                fullWidth
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <TextField
                className="input-field"
                label="Event Image"
                name="eventImage"
                value={formData.eventImage}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!errors.eventImage}
                helperText={errors.eventImage}
                variant="outlined"
                margin="normal"
                fullWidth
              />
            </div>
            <div className="form-group">
              <TextField
                className="input-field"
                label="Event End Time"
                name="eventEndTime"
                type="time"
                value={formData.eventEndTime}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!errors.eventEndTime}
                helperText={errors.eventEndTime}
                variant="outlined"
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            </div>
            <div className="form-group">
              <TextField
                className="input-field"
                label="Country"
                name="country"
                value={formData.country}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!errors.country}
                helperText={errors.country}
                variant="outlined"
                margin="normal"
                fullWidth
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group form-group-full">
              <TextField
                className="input-field"
                label="Event Timezone"
                name="eventTimezone"
                value={formData.eventTimezone}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!errors.eventTimezone}
                helperText={errors.eventTimezone}
                variant="outlined"
                margin="normal"
                fullWidth
              />
            </div>
          </div>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className="save-button"
            type="submit"
          >
            {isEditMode ? 'Update' : 'Save'}
          </Button>
        </form>
      </div>
    </Box>
  )
}

export default OnboardVenue
