import './styles.scss'

import EmailIcon from '@mui/icons-material/Email'
import PhoneIcon from '@mui/icons-material/Phone'
import {
  Button,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import ConfirmationModal from 'components/ConfirmationModal'
import Loader from 'components/Loader'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import apiAdapter from 'Services/apiAdapter'
import { IMAGE_URL, ROUTE_STRINGS } from 'Utils/Constants'

import OnboardEventModal from './Components/OnboardEventModal'

const Events = ({ setLoader }) => {
  const navigate = useNavigate()
  const [events, setEvents] = useState([])
  const [isModalOpen, setModalOpen] = useState(false)
  const [currentEvent, setCurrentEvent] = useState(null)
  const [eventIdToDelete, setEventIdToDelete] = useState(null)
  const [isConfirmationOpen, setConfirmationOpen] = useState(false)

  useEffect(() => {
    fetchEvents()
  }, [])

  const fetchEvents = async () => {
    setLoader(true)
    try {
      const response = await apiAdapter.getEvents()
      const {
        data: { eventResponseDTOList: eventData },
      } = response

      if (response.status) {
        console.log('eventData', eventData)
        setEvents(eventData)
      }
    } catch (error) {
      console.error('Error occurred while fetching events:', error)
    } finally {
      setLoader(false)
    }
  }

  const handleEventClick = (eventId) => {
    navigate(ROUTE_STRINGS.eventInfo, { state: { eventId } })
  }

  const handleOpenModal = (event = null) => {
    setCurrentEvent(event)
    setModalOpen(true)
  }

  const handleCloseModal = () => {
    setModalOpen(false)
    setCurrentEvent(null)
    fetchEvents()
  }

  const handleDelete = (userId) => {
    setEventIdToDelete(userId)
    setConfirmationOpen(true)
  }

  const handleDeleteConfirm = async () => {
    setConfirmationOpen(false)
    setLoader(true)

    try {
      const response = await apiAdapter.deleteEvent({
        eventId: eventIdToDelete,
      })

      if (response?.status) {
        swal('Success', 'Event deleted successfully!', 'success')
        // getRolesListByRoleId(selectedRole)
      }
    } catch (error) {
      console.error('Error occurred while deleting Event:', error)
      swal('Error', 'Failed to delete Event', 'error')
    } finally {
      setLoader(false)
    }
  }

  return (
    <Container className="table-container">
      <div className="actions-section">
        <div className="left-section"></div>
        <div className="right-section">
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleOpenModal()}
          >
            Onboard Event
          </Button>
        </div>
      </div>

      <div className="table-section">
        <TableContainer component={Paper}>
          <Table className="table" aria-label="events table">
            <TableHead className="table-head">
              <TableRow>
                <TableCell className="table-cell">Event Name</TableCell>
                <TableCell className="table-cell">Phone-Email</TableCell>
                <TableCell className="table-cell">Status</TableCell>
                <TableCell className="table-cell">Address</TableCell>
                <TableCell className="table-cell">End Time</TableCell>
                <TableCell className="table-cell" width="18%">
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {events.map((event) => (
                <TableRow key={event.eventEmail} className="events-table-row">
                  <TableCell
                    className="event-name-cell"
                    onClick={() => handleEventClick(event.eventId)}
                  >
                    {event.eventName}
                  </TableCell>
                  <TableCell className="phone-email-cell">
                    <span>
                      <PhoneIcon />
                      {event.eventPhone}
                    </span>
                    <br />
                    <span>
                      <EmailIcon />
                      {event.eventEmail}
                    </span>
                  </TableCell>
                  <TableCell>{event.eventStatus}</TableCell>
                  <TableCell>
                    {event.eventAddress}, {event.city}
                    <br /> {event.state}, {event.countryName} -{' '}
                    {event.eventPostalCode}
                  </TableCell>
                  <TableCell>
                    {event.evemtEndtime}, {event.eventTimezone}
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      className="roles-edit-button"
                      onClick={() => handleOpenModal(event)}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="contained"
                      className="users-delete-button m-l-5"
                      onClick={() => handleDelete(event.eventId)}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      {/* OnboardEventModal Modal */}
      <OnboardEventModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        initialData={currentEvent}
      />

      <ConfirmationModal
        isOpen={isConfirmationOpen}
        onClose={() => setConfirmationOpen(false)}
        onConfirm={handleDeleteConfirm}
        title="Confirm Delete"
        message="Are you sure you want to delete this event?"
        confirmButtonText="Yes, Delete"
        cancelButtonText="Cancel"
      />
    </Container>
  )
}

export default Loader(Events)
