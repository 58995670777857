import { ROUTE_STRINGS } from 'Utils/Constants'

export const ROLES = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  RESTAURANT_ADMIN: 'Restaurant-Admin',
  EVENT_ADMIN: 'Event-Admin',
}

export const roleConfig = {
  [ROLES.SUPER_ADMIN]: {
    menuItems: [
      { text: 'Home', link: ROUTE_STRINGS.home },
      { text: 'Roles', link: ROUTE_STRINGS.roles },
      { text: 'Users', link: ROUTE_STRINGS.users },
      {
        text: 'Restaurant Management',
        subMenu: [
          { text: 'List Restaurants', link: ROUTE_STRINGS.listRestaurants },
          // { text: 'Category Management', link: ROUTE_STRINGS.categories },
          // { text: 'QR Management', link: ROUTE_STRINGS.qrManagement },
        ],
      },
      {
        text: 'Event Management',
        subMenu: [
          { text: 'List Events', link: ROUTE_STRINGS.listEvents },
          // { text: 'Category Management', link: ROUTE_STRINGS.categories },
          // { text: 'QR Management', link: ROUTE_STRINGS.qrManagement },
        ],
      },
    ],
    allowedRoutes: [
      ROUTE_STRINGS.home,
      ROUTE_STRINGS.roles,
      ROUTE_STRINGS.users,
      // ROUTE_STRINGS.venueManagement,
      // ROUTE_STRINGS.item,
      // ROUTE_STRINGS.categories,
      // ROUTE_STRINGS.modifers,
      // ROUTE_STRINGS.staff,
      // ROUTE_STRINGS.salesSummary,
      ROUTE_STRINGS.tableManagement,
      ROUTE_STRINGS.qrManagement,
      // ROUTE_STRINGS.orderHistory,
      // ROUTE_STRINGS.accountSettings,
      // ROUTE_STRINGS.createItem,
      ROUTE_STRINGS.listRestaurants,
      ROUTE_STRINGS.onboardRestaurant,
      ROUTE_STRINGS.restaurantInfo,
      ROUTE_STRINGS.listEvents,
      ROUTE_STRINGS.onboardVenue,
      ROUTE_STRINGS.eventInfo,
    ],
    permissions: {
      canEdit: true,
    },
  },
  [ROLES.RESTAURANT_ADMIN]: {
    menuItems: [
      { text: 'Home', link: ROUTE_STRINGS.home },
      {
        text: 'Restaurant Management',
        subMenu: [
          { text: 'Restaurant Info', link: ROUTE_STRINGS.restaurantInfo },
          { text: 'Table Management', link: ROUTE_STRINGS.tableManagement },
        ],
      },
      { text: 'Order History', link: ROUTE_STRINGS.orderHistory },
      {
        text: 'Reports',
        subMenu: [{ text: 'Sales Summary', link: ROUTE_STRINGS.salesSummary }],
      },
    ],
    allowedRoutes: [
      ROUTE_STRINGS.home,
      ROUTE_STRINGS.restaurantInfo,
      ROUTE_STRINGS.tableManagement,
      ROUTE_STRINGS.orderHistory,
      ROUTE_STRINGS.salesSummary,
    ],
    permissions: {
      canEdit: true,
    },
  },
  [ROLES.EVENT_ADMIN]: {
    menuItems: [
      { text: 'Home', link: ROUTE_STRINGS.home },
      {
        text: 'Event Management',
        subMenu: [{ text: 'Event Info', link: ROUTE_STRINGS.venueInfo }],
      },
    ],
    allowedRoutes: [
      ROUTE_STRINGS.home,
      ROUTE_STRINGS.eventInfo,
      ROUTE_STRINGS.onboardEvent,
    ],
    permissions: {
      canEdit: false,
    },
  },
}

export const getRoleConfig = (role) => roleConfig[role] || {}
