import './styles.scss'

import CloseIcon from '@mui/icons-material/Close'
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import apiAdapter from 'Services/apiAdapter'
import swal from 'sweetalert'

const CreateUserModal = ({
  isOpen,
  onClose,
  initialData = null,
  allActiveRoles,
}) => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    password: '',
    dob: '2004-01-01',
    userStatus: 'ACTIVE',
    userEmail: '',
    mobileNo: '',
    userRoleId: allActiveRoles.length > 0 ? allActiveRoles[0].userRoleId : '',
  })

  const [errors, setErrors] = useState({})
  const [isFormValid, setFormValid] = useState(false)

  useEffect(() => {
    if (initialData) {
      setFormData({
        userId: initialData.userId,
        firstName: initialData.firstName,
        lastName: initialData.lastName,
        password: initialData.password,
        dob: initialData.dob,
        userStatus: initialData.userStatus,
        userEmail: initialData.userEmail,
        mobileNo: initialData.mobileNo,
        userRoleId: initialData.userRoleId,
        dateOfBirth: initialData.dob,
      })
    } else {
      resetForm()
    }
  }, [initialData, allActiveRoles])

  const resetForm = () => {
    setFormData({
      firstName: '',
      lastName: '',
      password: '',
      dob: '2004-01-01',
      userStatus: 'ACTIVE',
      userEmail: '',
      mobileNo: '',
      userRoleId: allActiveRoles.length > 0 ? allActiveRoles[0].userRoleId : '',
    })
    setErrors({})
    setFormValid(false)
  }

  const validateForm = (data) => {
    const newErrors = {}
    let valid = true

    if (!data.firstName) {
      newErrors.firstName = 'Required'
      valid = false
    }
    if (!data.lastName) {
      newErrors.lastName = 'Required'
      valid = false
    }
    if (!data.password) {
      newErrors.password = 'Required'
      valid = false
    }
    if (!data.userEmail) {
      newErrors.userEmail = 'Required'
      valid = false
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(data.userEmail)
    ) {
      newErrors.userEmail = 'Invalid email address'
      valid = false
    }
    if (!data.mobileNo) {
      newErrors.mobileNo = 'Required'
      valid = false
    } else if (!/^\d{10}$/.test(data.mobileNo)) {
      newErrors.mobileNo = 'Mobile number must be 10 digits'
      valid = false
    }
    setErrors(newErrors)
    setFormValid(valid)

    return valid
  }

  const handleChange = (event) => {
    const { name, value } = event.target
    const updatedFormData = { ...formData, [name]: value }

    setFormData(updatedFormData)
    validateForm(updatedFormData)
  }

  const handleBlur = (event) => {
    validateForm(formData)
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (!validateForm(formData)) return

    try {
      let response

      if (initialData) {
        // TODO :: updateUser API has dateOfBirth field which is diff than dob in createUser
        // TODO :: updateUser API is correct what i am looking
        const appendedFormData = {
          ...formData,
          dateOfBirth: '02-01-2002',
          fullName: `${formData.firstName} ${formData.lastName}`,
        }

        response = await apiAdapter.updateUser(appendedFormData)
        swal('Success', 'User updated successfully!', 'success')
      } else {
        response = await apiAdapter.createUser(formData)
        swal('Success', 'User created successfully!', 'success')
      }
      onClose()
      resetForm()
    } catch (error) {
      console.error('Error occurred:', error)
      swal('Error', error.response.data.message, 'error')
    }
  }

  return (
    <Modal
      open={isOpen}
      onClose={() => {
        onClose()
        resetForm()
      }}
    >
      <Box className="modal-container">
        <div className="modal-header">
          <Typography variant="h6">
            {initialData ? 'Edit User' : 'Create User'}
          </Typography>
          <IconButton
            className="close-button"
            onClick={() => {
              onClose()
              resetForm()
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <div className="modal-content">
          <form onSubmit={handleSubmit}>
            <div className="form-row">
              <div className="form-group">
                <TextField
                  className="input-field"
                  label="First Name"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errors.firstName}
                  helperText={errors.firstName}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                />
              </div>
              <div className="form-group">
                <TextField
                  className="input-field"
                  label="Last Name"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errors.lastName}
                  helperText={errors.lastName}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <TextField
                  className="input-field"
                  label="Password"
                  name="password"
                  type="password"
                  value={formData.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errors.password}
                  helperText={errors.password}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  autoComplete="new-password"
                />
              </div>
              <div className="form-group">
                <TextField
                  className="input-field"
                  label="Date of Birth"
                  name="dob"
                  type="date"
                  value={formData.dob}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errors.dob}
                  helperText={errors.dob}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <TextField
                  className="input-field"
                  label="Email"
                  name="userEmail"
                  type="email"
                  value={formData.userEmail}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errors.userEmail}
                  helperText={errors.userEmail}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  autoComplete="off"
                />
              </div>
              <div className="form-group">
                <TextField
                  className="input-field"
                  label="Mobile Number"
                  name="mobileNo"
                  value={formData.mobileNo}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errors.mobileNo}
                  helperText={errors.mobileNo}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  inputProps={{ maxLength: 10 }}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <FormControl
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  error={!!errors.userStatus}
                >
                  <InputLabel id="status-dropdown">User Status</InputLabel>
                  <Select
                    className="dropdown"
                    labelId="status-dropdown"
                    id="status-dropdown-select"
                    value={formData.userStatus}
                    name="userStatus"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="User Status"
                  >
                    <MenuItem value="ACTIVE">Active</MenuItem>
                    <MenuItem value="DELETED">Deleted</MenuItem>
                  </Select>
                  {errors.userStatus && (
                    <FormHelperText>{errors.userStatus}</FormHelperText>
                  )}
                </FormControl>
              </div>
              <div className="form-group">
                <FormControl
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  error={!!errors.userRoleId}
                >
                  <InputLabel id="user-role-dropdown">Select Roles:</InputLabel>
                  <Select
                    className="dropdown"
                    labelId="user-role-dropdown"
                    id="user-role-dropdown-select"
                    value={formData.userRoleId}
                    name="userRoleId"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Select Roles"
                  >
                    {allActiveRoles.map((role) => (
                      <MenuItem key={role.userRoleId} value={role.userRoleId}>
                        {role.userRole}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.userRoleId && (
                    <FormHelperText>{errors.userRoleId}</FormHelperText>
                  )}
                </FormControl>
              </div>
            </div>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className="save-button"
              type="submit"
              disabled={!isFormValid}
            >
              {initialData ? 'Update' : 'Save'}
            </Button>
          </form>
        </div>
      </Box>
    </Modal>
  )
}

export default CreateUserModal
