import './styles.scss'

import CloseIcon from '@mui/icons-material/Close'
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import Loader from 'components/Loader'
import React, { useEffect, useState } from 'react'
import apiAdapter from 'Services/apiAdapter'
import swal from 'sweetalert'
import { IMAGE_URL, STORAGE_KEYS } from 'Utils/Constants'
import { getLocal } from 'Utils/LocalStorageHandler'

const OnboardEvent = ({
  isOpen,
  onClose,
  eventId,
  initialData = null,
  setLoader,
}) => {
  const userDetails = getLocal(STORAGE_KEYS.userId)
  const [formData, setFormData] = useState({
    eventName: '',
    eventPhone: '',
    eventEmail: '',
    eventStartTime: '',
    eventEndTime: '',
    eventStatus: '',
    eventDescription: '',
    eventAddress: '',
    eventPostalCode: '',
    city: '',
    state: '',
    country: '',
    locLatitude: '',
    locLongitude: '',
    eventImage: null,
    adminFirstName: '',
    adminLastName: '',
    adminPassword: '',
    adminEmail: '',
    adminMobileNo: '',
    adminGender: '',
    userRoleId: '',
  })

  const [roles, setRoles] = useState([])
  const [errors, setErrors] = useState({})
  const [imagePreview, setImagePreview] = useState(null)

  useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    if (initialData) {
      setFormData({
        eventName: initialData.eventName || '',
        eventPhone: initialData.eventPhone || '',
        eventEmail: initialData.eventEmail || '',
        eventStartTime: initialData.eventStartTime || '',
        eventEndTime: initialData.eventEndTime || '',
        eventStatus: initialData.eventStatus || '',
        eventDescription: initialData.eventDescription || '',
        eventAddress: initialData.eventAddress || '',
        eventPostalCode: initialData.eventPostalCode || '',
        city: initialData.city || '',
        state: initialData.state || '',
        country: initialData.country || '',
        locLatitude: initialData.locLatitude || '',
        locLongitude: initialData.locLongitude || '',
        eventImage: null,
        adminFirstName: initialData.adminFirstName || '',
        adminLastName: initialData.adminLastName || '',
        adminPassword: '',
        adminEmail: initialData.adminEmail || '',
        adminMobileNo: initialData.adminMobileNo || '',
        adminGender: initialData.adminGender || '',
        userRoleId: initialData.userRoleId || '',
      })

      if (initialData.eventImage) {
        setImagePreview(`${IMAGE_URL}${initialData.eventImage}`)
      }
    }
  }, [initialData])

  const init = async () => {
    setLoader(true)
    try {
      const response = await apiAdapter.getAllActiveRoles()

      if (response.data.status) {
        const {
          data: { data: rolesData },
        } = response

        setRoles(rolesData)
      }
    } catch (error) {
      console.error('Error fetching roles:', error)
    } finally {
      setLoader(false)
    }
  }

  const handleChange = (event) => {
    const { name, value, type, files } = event.target

    setFormData({ ...formData, [name]: type === 'file' ? files[0] : value })
    setErrors({ ...errors, [name]: '' })
  }

  const handleImageChange = (event) => {
    const file = event.target.files[0]
    const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg']

    if (file && allowedTypes.includes(file.type)) {
      setFormData({ ...formData, eventImage: file })
      setImagePreview(URL.createObjectURL(file))
      setErrors({ ...errors, eventImage: '' })
    } else {
      setErrors({
        ...errors,
        eventImage: 'Only PNG, JPG, or JPEG images are allowed',
      })
    }
  }

  const handleBlur = (event) => {
    const { name, value } = event.target
    let errorMsg = ''

    if (!value) errorMsg = 'Required'
    setErrors({ ...errors, [name]: errorMsg })
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    const newErrors = {}

    Object.keys(formData).forEach((key) => {
      if (!formData[key]) newErrors[key] = 'Required'
    })

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors)

      return
    }

    const formDataToSubmit = new FormData()

    Object.entries(formData).forEach(([key, value]) => {
      if (value !== null && value !== '') {
        formDataToSubmit.append(key, value)
      }
    })

    setLoader(true)
    try {
      let response

      if (initialData) {
        response = await apiAdapter.updateEvent(
          initialData.eventId,
          formDataToSubmit,
        )
        swal('Success', 'Event updated successfully!', 'success')
      } else {
        response = await apiAdapter.createEvent(formDataToSubmit)
        swal('Success', 'Event created successfully!', 'success')
      }
      onClose()
    } catch (error) {
      console.error('Error occurred:', error)
      swal(
        'Error',
        'An error occurred while processing your request.',
        'error',
      )
    } finally {
      setLoader(false)
    }
  }

  const handleCloseModal = () => {
    setFormData({
      eventName: '',
      eventPhone: '',
      eventEmail: '',
      eventStartTime: '',
      eventEndTime: '',
      eventStatus: '',
      eventDescription: '',
      eventAddress: '',
      eventPostalCode: '',
      city: '',
      state: '',
      country: '',
      locLatitude: '',
      locLongitude: '',
      eventImage: null,
      adminFirstName: '',
      adminLastName: '',
      adminPassword: '',
      adminEmail: '',
      adminMobileNo: '',
      adminGender: '',
      userRoleId: '',
    })
    setErrors({})
    setImagePreview(null)
    onClose()
  }

  return (
    <Modal open={isOpen} onClose={handleCloseModal}>
      <Box className="modal-container">
        <div className="modal-header">
          <Typography variant="h6">
            {initialData ? 'Edit Event' : 'Onboard Event'}
          </Typography>
          <IconButton
            className="close-button"
            onClick={() => handleCloseModal()}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <div className="modal-content">
          <form onSubmit={handleSubmit}>
            <div className="form-row">
              <div className="form-section">
                <TextField
                  className="input-field"
                  label="Event Name"
                  name="eventName"
                  value={formData.eventName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errors.eventName}
                  helperText={errors.eventName}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                />
                <TextField
                  className="input-field"
                  label="Event Phone"
                  name="eventPhone"
                  value={formData.eventPhone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errors.eventPhone}
                  helperText={errors.eventPhone}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                />
                <TextField
                  className="input-field"
                  label="Event Email"
                  name="eventEmail"
                  value={formData.eventEmail}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errors.eventEmail}
                  helperText={errors.eventEmail}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                />
              </div>
              <div className="image-section">
                <div className="image-preview">
                  {imagePreview ? (
                    <img src={imagePreview} alt="Preview" />
                  ) : (
                    'No Image'
                  )}
                </div>
                <label htmlFor="eventImage">
                  <Button
                    variant="contained"
                    component="span"
                    className="upload-button"
                  >
                    Upload Image
                  </Button>
                </label>
                <input
                  type="file"
                  accept="image/png, image/jpg, image/jpeg"
                  onChange={handleImageChange}
                  style={{ display: 'none' }}
                  id="eventImage"
                />
                {errors.eventImage && (
                  <div className="error">{errors.eventImage}</div>
                )}
              </div>
            </div>

            <div className="form-row">
              <TextField
                className="input-field"
                label="Event Start Time"
                name="eventStartTime"
                type="datetime-local"
                value={formData.eventStartTime}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!errors.eventStartTime}
                helperText={errors.eventStartTime}
                variant="outlined"
                margin="normal"
                fullWidth
              />
              <TextField
                className="input-field"
                label="Event End Time"
                name="eventEndTime"
                type="datetime-local"
                value={formData.eventEndTime}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!errors.eventEndTime}
                helperText={errors.eventEndTime}
                variant="outlined"
                margin="normal"
                fullWidth
              />
              <FormControl
                variant="outlined"
                fullWidth
                margin="normal"
                error={!!errors.eventStatus}
              >
                <InputLabel id="status-dropdown">Event Status</InputLabel>
                <Select
                  className="dropdown"
                  labelId="status-dropdown"
                  id="status-dropdown-select"
                  value={formData.eventStatus}
                  name="eventStatus"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label="Event Status"
                >
                  <MenuItem value="UPCOMING">Upcoming</MenuItem>
                  <MenuItem value="ONGOING">Ongoing</MenuItem>
                  <MenuItem value="COMPLETED">Completed</MenuItem>
                </Select>
                {errors.eventStatus && (
                  <FormHelperText>{errors.eventStatus}</FormHelperText>
                )}
              </FormControl>
            </div>

            <div className="form-row">
              <TextField
                className="input-field"
                label="Event Description"
                name="eventDescription"
                value={formData.eventDescription}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!errors.eventDescription}
                helperText={errors.eventDescription}
                variant="outlined"
                margin="normal"
                fullWidth
              />
            </div>

            <div className="form-row">
              <TextField
                className="input-field"
                label="Address"
                name="eventAddress"
                value={formData.eventAddress}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!errors.eventAddress}
                helperText={errors.eventAddress}
                variant="outlined"
                margin="normal"
                fullWidth
              />
              <TextField
                className="input-field"
                label="City"
                name="city"
                value={formData.city}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!errors.city}
                helperText={errors.city}
                variant="outlined"
                margin="normal"
                fullWidth
              />
              <TextField
                className="input-field"
                label="State"
                name="state"
                value={formData.state}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!errors.state}
                helperText={errors.state}
                variant="outlined"
                margin="normal"
                fullWidth
              />
            </div>

            <div className="form-row">
              <TextField
                className="input-field"
                label="Country"
                name="country"
                value={formData.country}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!errors.country}
                helperText={errors.country}
                variant="outlined"
                margin="normal"
                fullWidth
              />
              <TextField
                className="input-field"
                label="Postal Code"
                name="eventPostalCode"
                value={formData.eventPostalCode}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!errors.eventPostalCode}
                helperText={errors.eventPostalCode}
                variant="outlined"
                margin="normal"
                fullWidth
              />
              <TextField
                className="input-field"
                label="Latitude"
                name="locLatitude"
                value={formData.locLatitude}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!errors.locLatitude}
                helperText={errors.locLatitude}
                variant="outlined"
                margin="normal"
                fullWidth
              />
            </div>

            <div className="form-row">
              <TextField
                className="input-field"
                label="Longitude"
                name="locLongitude"
                value={formData.locLongitude}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!errors.locLongitude}
                helperText={errors.locLongitude}
                variant="outlined"
                margin="normal"
                fullWidth
              />
            </div>

            {/* Admin Section */}
            <Typography variant="h6" gutterBottom>
              Admin Information
            </Typography>

            <div className="form-row">
              <TextField
                className="input-field"
                label="Admin First Name"
                name="adminFirstName"
                value={formData.adminFirstName}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!errors.adminFirstName}
                helperText={errors.adminFirstName}
                variant="outlined"
                margin="normal"
                fullWidth
              />
              <TextField
                className="input-field"
                label="Admin Last Name"
                name="adminLastName"
                value={formData.adminLastName}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!errors.adminLastName}
                helperText={errors.adminLastName}
                variant="outlined"
                margin="normal"
                fullWidth
              />
              <TextField
                className="input-field"
                label="Admin Password"
                name="adminPassword"
                type="password"
                value={formData.adminPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!errors.adminPassword}
                helperText={errors.adminPassword}
                variant="outlined"
                margin="normal"
                fullWidth
              />
            </div>

            <div className="form-row">
              <TextField
                className="input-field"
                label="Admin Email"
                name="adminEmail"
                type="email"
                value={formData.adminEmail}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!errors.adminEmail}
                helperText={errors.adminEmail}
                variant="outlined"
                margin="normal"
                fullWidth
              />
              <TextField
                className="input-field"
                label="Admin Mobile Number"
                name="adminMobileNo"
                value={formData.adminMobileNo}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!errors.adminMobileNo}
                helperText={errors.adminMobileNo}
                variant="outlined"
                margin="normal"
                fullWidth
              />
              <TextField
                className="input-field"
                label="Admin Gender"
                name="adminGender"
                value={formData.adminGender}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!errors.adminGender}
                helperText={errors.adminGender}
                variant="outlined"
                margin="normal"
                fullWidth
              />
            </div>

            <FormControl
              variant="outlined"
              fullWidth
              margin="normal"
              error={!!errors.userRoleId}
            >
              <InputLabel id="role-dropdown">User Role</InputLabel>
              <Select
                className="dropdown"
                labelId="role-dropdown"
                id="role-dropdown-select"
                value={formData.userRoleId}
                name="userRoleId"
                onChange={handleChange}
                onBlur={handleBlur}
                label="User Role"
              >
                {roles.map((role) => (
                  <MenuItem key={role.userRoleId} value={role.userRoleId}>
                    {role.userRole}
                  </MenuItem>
                ))}
              </Select>
              {errors.userRoleId && (
                <FormHelperText>{errors.userRoleId}</FormHelperText>
              )}
            </FormControl>

            <Button
              fullWidth
              variant="contained"
              color="primary"
              className="save-button"
              type="submit"
            >
              {initialData ? 'Update' : 'Save'}
            </Button>
          </form>
        </div>
      </Box>
    </Modal>
  )
}

export default Loader(OnboardEvent)
