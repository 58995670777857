import './styles.scss'

import { Edit as EditIcon } from '@mui/icons-material'
import { Box, IconButton, Tooltip, Typography } from '@mui/material'
import Loader from 'components/Loader'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import apiAdapter from 'Services/apiAdapter'
import { IMAGE_URL, STORAGE_KEYS } from 'Utils/Constants'
import { getLocal } from 'Utils/LocalStorageHandler'

const EventInfo = ({ setLoader }) => {
  const location = useLocation()
  const eventId = location?.state?.eventId
  const [eventData, setEventData] = useState(null)

  useEffect(() => {
    if (eventId) {
      fetchEventData()
    }
  }, [eventId])

  const fetchEventData = async () => {
    setLoader(true)
    try {
      const { data: eventData } = await apiAdapter.getEventById(eventId)

      setEventData(eventData)
    } catch (error) {
      console.error('Error fetching event data:', error)
    } finally {
      setLoader(false)
    }
  }

  return (
    <Box className="event-info-container">
      <div className="title-section">
        <div className="left-section">
          <Typography variant="h4" className="title">
            Event Management
          </Typography>
        </div>
      </div>

      {eventData && (
        <Box className="event-info-section">
          <Box className="event-info">
            <div className="left-section">
              <Typography variant="h6" className="section-title">
                Event Info
              </Typography>
            </div>
          </Box>

          <Box className="info-details">
            <Typography variant="body1" className="info-field">
              <span className="label">Event Name:</span> {eventData.eventName}
            </Typography>
            <Typography variant="body1" className="info-field">
              <span className="label">Phone:</span> {eventData.eventPhone}
            </Typography>
            <Typography variant="body1" className="info-field">
              <span className="label">Email:</span> {eventData.eventEmail}
            </Typography>
            <Typography variant="body1" className="info-field">
              <span className="label">Admin ID:</span> {eventData.eventAdminId}
            </Typography>
            <Typography variant="body1" className="info-field">
              <span className="label">Start Time - End Time:</span>{' '}
              {eventData.eventStarttime} - {eventData.eventEndtime} (
              {eventData.eventTimezone})
            </Typography>
            <Typography variant="body1" className="info-field">
              <span className="label">Status:</span> {eventData.eventStatus}
            </Typography>
            <Typography variant="body1" className="info-field">
              <span className="label">Description:</span>{' '}
              {eventData.eventDescription}
            </Typography>
            {eventData.createdBy && (
              <Typography variant="body1" className="info-field">
                <span className="label">Created By - Created On:</span>{' '}
                {eventData.createdBy} - {eventData.createdOn}
              </Typography>
            )}
            {eventData.modifiedBy && (
              <Typography variant="body1" className="info-field">
                <span className="label">Modified By - Modified On:</span>{' '}
                {eventData.modifiedBy} - {eventData.modifiedOn}
              </Typography>
            )}
            <Typography variant="body1" className="info-field">
              <span className="label">Location (Lat, Long):</span>{' '}
              {eventData.locLatitude}, {eventData.locLongitude}
            </Typography>
            <Typography variant="body1" className="info-field">
              <span className="label">Address:</span> {eventData.eventAddress},{' '}
              {eventData.city}, {eventData.state}, {eventData.countryName}
            </Typography>
            <Typography variant="body1" className="info-field">
              <span className="label">Postal Code:</span>{' '}
              {eventData.eventPostalCode}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default Loader(EventInfo)
