import './styles.scss'

import EmailIcon from '@mui/icons-material/Email'
import PhoneIcon from '@mui/icons-material/Phone'
import {
  Button,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import Loader from 'components/Loader'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import apiAdapter from 'Services/apiAdapter'
import { IMAGE_URL, ROUTE_STRINGS } from 'Utils/Constants'
import { getLocal } from 'Utils/LocalStorageHandler'

import OnboardRestaurant from './Components/OnboardRestaurant'

const Restaurants = ({ setLoader }) => {
  const navigate = useNavigate()
  const userId = getLocal('userId')

  const [restaurants, setRestaurants] = useState([])
  const [isModalOpen, setModalOpen] = useState(false)
  const [currentRestaurant, setCurrentRestaurant] = useState(null)

  useEffect(() => {
    init()
  }, [])

  const init = async () => {
    setLoader(true)
    try {
      const response = await apiAdapter.getRestaurants()

      const {
        data: { data: restaurantData },
      } = response

      if (response.data.status) {
        setRestaurants(restaurantData.restaurantResponseDTOList)
      }
    } catch (error) {
      console.error('Error occurred while fetching restaurants:', error)
    } finally {
      setLoader(false)
    }
  }

  const handleRestaurantClick = (restaurantId) => {
    navigate(ROUTE_STRINGS.restaurantInfo, { state: { restaurantId } })
  }

  const handleOpenModal = (restaurant = null) => {
    setCurrentRestaurant(restaurant)
    setModalOpen(true)
  }

  const handleCloseModal = () => {
    setModalOpen(false)
    setCurrentRestaurant(null)
    init()
  }

  return (
    <Container className="table-container">
      <div className="actions-section">
        <div className="left-section"></div>
        <div className="right-section">
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleOpenModal()}
          >
            Onboard Restaurant
          </Button>
        </div>
      </div>

      <div className="table-section">
        <TableContainer component={Paper}>
          <Table className="table" aria-label="restaurants table">
            <TableHead className="table-head">
              <TableRow>
                <TableCell className="table-cell">Restaurant Name</TableCell>
                <TableCell className="table-cell">Phone-Email</TableCell>
                <TableCell className="table-cell">Status</TableCell>
                <TableCell className="table-cell">Venue Preference</TableCell>
                <TableCell className="table-cell">Address</TableCell>
                <TableCell className="table-cell">End Time</TableCell>
                <TableCell className="table-cell" width="18%">
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {restaurants.map((restaurant) => (
                <TableRow
                  key={restaurant.restaurantEmail}
                  className="restaurants-table-row"
                >
                  <TableCell
                    className="restaurant-name-cell"
                    onClick={() =>
                      handleRestaurantClick(restaurant.restaurantId)
                    }
                  >
                    {restaurant.restaurantName}
                  </TableCell>
                  <TableCell className="phone-email-cell">
                    <span>
                      <PhoneIcon />
                      {restaurant.restaurantPhone}
                    </span>
                    <br />
                    <span>
                      <EmailIcon />
                      {restaurant.restaurantEmail}
                    </span>
                  </TableCell>
                  <TableCell>{restaurant.restaurantStatus}</TableCell>
                  <TableCell>{restaurant.venuePreference}</TableCell>
                  <TableCell>
                    {restaurant.restaurantAddress}, {restaurant.city}
                    <br /> {restaurant.state}, {restaurant.countryName} -{' '}
                    {restaurant.restaurantPostalCode}
                  </TableCell>
                  <TableCell>
                    {restaurant.restaurantEndtime},{' '}
                    {restaurant.restaurantTimezone}
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      className="roles-edit-button m-r-5"
                      onClick={() =>
                        navigate(ROUTE_STRINGS.tableManagement, {
                          state: { restaurantId: restaurant.restaurantId },
                        })
                      }
                    >
                      View Table
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      className="roles-edit-button"
                      onClick={() => handleOpenModal(restaurant)}
                    >
                      Edit
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      {/* OnboardRestaurant Modal */}
      <OnboardRestaurant
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        initialData={currentRestaurant}
      />
    </Container>
  )
}

export default Loader(Restaurants)
