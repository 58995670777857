import './styles.scss'

import {
  Button,
  Checkbox,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination, // Import TablePagination for pagination control
  TableRow,
} from '@material-ui/core'
import Loader from 'components/Loader'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import apiAdapter from 'Services/apiAdapter'
import swal from 'sweetalert'
import { STORAGE_KEYS } from 'Utils/Constants'
import { getLocal } from 'Utils/LocalStorageHandler'

import CreateRoleModal from './Components/CreateRole'

const Roles = ({ setLoader }) => {
  const navigate = useNavigate()
  const userId = getLocal(STORAGE_KEYS.userId)

  const [allActiveRoles, setAllActiveRoles] = useState([])
  const [selectedRole, setSelectedRole] = useState('')
  const [roleUsers, setRoleUsers] = useState([])
  const [isModalOpen, setModalOpen] = useState(false)
  const [currentRowData, setCurrentRowData] = useState(null)

  // Pagination states
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    if (selectedRole) {
      getRolesListByRoleId(selectedRole)
    }
  }, [selectedRole])

  const init = async () => {
    setLoader(true)
    // setShowTableMessage(true)
    try {
      const response = await apiAdapter.getAllActiveRoles()

      if (response.data.status) {
        const {
          data: { data: rolesData },
        } = response

        setAllActiveRoles(rolesData)
        if (rolesData.length > 0) {
          setSelectedRole(rolesData[0].userRoleId)
        }
      }
    } catch (error) {
      console.error('Error occurred while fetching roles:', error)
    } finally {
      setLoader(false)
      // setShowTableMessage(false)
    }
  }

  const getRolesListByRoleId = async (roleId) => {
    setLoader(true)
    // setShowTableMessage(true)
    try {
      const response = await apiAdapter.getUsersByRoleId({ roleId })

      if (response.data.status) {
        const {
          data: { data: rolesList },
        } = response

        setRoleUsers(rolesList)
      }
    } catch (error) {
      console.error('Error occurred while fetching role users:', error)
    } finally {
      setLoader(false)
      // setShowTableMessage(false)
    }
  }

  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value)
  }

  const handleOpenModal = (roleData = null) => {
    if (roleData) {
      setCurrentRowData(roleData)
    }
    setModalOpen(true)
  }

  const handleCloseModal = () => {
    setModalOpen(false)
    setCurrentRowData(null)
  }

  // Pagination handlers
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  // Function to be called after role is created or updated
  const handleRoleCreatedOrUpdated = () => {
    init() // Fetch updated roles after a role is created/updated
    handleCloseModal() // Close the modal
  }

  return (
    <Container className="table-container">
      <div className="actions-section">
        <div className="left-section">
          {/* <FormControl variant="outlined" className="roles-formControl">
            <InputLabel id="roles-dropdown-label">Select Roles:</InputLabel>
            <Select labelId="roles-dropdown-label" id="roles-dropdown" value={selectedRole} onChange={handleRoleChange} label="Get By">
              {allActiveRoles.map((role) => (
                <MenuItem key={role.userRoleId} value={role.userRoleId}>
                  {role.userRole}
                </MenuItem>
              ))}
            </Select>
          </FormControl> */}
        </div>
        <div className="right-section">
          {/* <Button variant="contained" color="primary" onClick={() => navigate(ROUTE_STRINGS.login)}>
            Get All Roles
          </Button> */}
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleOpenModal()}
          >
            Create Role
          </Button>
        </div>
      </div>

      <div className="table-section">
        {/* console error is due to data issue, fix is below */}
        {/* https://github.com/mbrn/material-table/issues/1074 */}
        <TableContainer component={Paper}>
          <Table className="table" aria-label="roles table">
            <TableHead className="table-head">
              <TableRow>
                <TableCell className="table-cell">User Role</TableCell>
                <TableCell className="table-cell">Status</TableCell>
                <TableCell className="table-cell">Last Updated By</TableCell>
                <TableCell className="table-cell">Last Updated On</TableCell>
                <TableCell className="table-cell text-align-center">
                  Parent Association
                </TableCell>
                <TableCell className="table-cell">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allActiveRoles
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) // Implement pagination by slicing data
                .map((role) => (
                  <TableRow key={role.userRoleId} className="roles-table-row">
                    <TableCell>{role.userRole}</TableCell>
                    <TableCell>{role.status}</TableCell>
                    <TableCell>{role.updatedBy}</TableCell>
                    <TableCell>{role.updatedOn}</TableCell>
                    <TableCell className="text-align-center">
                      <Checkbox
                        checked={role.parentAssociation}
                        name="parentAssociation"
                        color="primary"
                        disabled
                      />
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="primary"
                        className="roles-edit-button"
                        onClick={() => handleOpenModal(role)}
                      >
                        Edit
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <CreateRoleModal
            isOpen={isModalOpen}
            onClose={handleCloseModal}
            initialData={currentRowData}
            onSuccess={handleRoleCreatedOrUpdated} // Pass success callback
          />
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]} // Page size options
            component="div"
            count={allActiveRoles.length} // Total number of rows
            rowsPerPage={rowsPerPage} // Rows per page
            page={page} // Current page
            onPageChange={handleChangePage} // Handle page change
            onRowsPerPageChange={handleChangeRowsPerPage} // Handle rows per page change
          />
        </TableContainer>
      </div>
    </Container>
  )
}

export default Loader(Roles)
