import './styles.scss'

import {
  Add as AddIcon,
  AddCard as AddCardIcon,
  BarChart,
  Edit as EditIcon,
  MonetizationOnIcon,
  Receipt,
  TableChart,
} from '@mui/icons-material'
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material'
import { noImageAdded } from 'Assets/Images'
import Loader from 'components/Loader'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import apiAdapter from 'Services/apiAdapter'
import { IMAGE_URL, ROUTE_STRINGS, STORAGE_KEYS } from 'Utils/Constants'
import { ROLES } from 'Utils/Constants/RolesConfig'
import { getLocal } from 'Utils/LocalStorageHandler'

import AddCategoryModal from '../AddCategoryModal'
import AddSubCategoryModal from '../AddSubCategoryModal'
import AddTaxModal from '../AddTaxModal'
import ItemModal from '../ItemModal'

const ViewRestaurantInfo = ({ setLoader }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const { user } = getLocal(STORAGE_KEYS.userDetails)
  const userRole = getLocal(STORAGE_KEYS.userRole)
  const restaurantId = location?.state?.restaurantId || user.restaurantId
  const [restaurantData, setRestaurantData] = useState(null)
  const [selectedSubCategory, setSelectedSubCategory] = useState(null)
  const [isAddCategoryModalOpen, setAddCategoryModalOpen] = useState(false)
  const [isAddSubCategoryModalOpen, setAddSubCategoryModalOpen] =
    useState(false)
  const [currentCategoryId, setCurrentCategoryId] = useState(null)
  const [initialCategoryData, setInitialCategoryData] = useState(null)
  const [initialSubCategoryData, setInitialSubCategoryData] = useState(null)
  const [isAddTaxModalOpen, setAddTaxModalOpen] = useState(false)

  useEffect(() => {
    if (restaurantId) {
      fetchRestaurantData()
    }
  }, [restaurantId])

  const fetchRestaurantData = async () => {
    setLoader(true)
    try {
      const response = await apiAdapter.getRestaurantById(restaurantId)

      setRestaurantData(response.data.data)
    } catch (error) {
      console.error('Error fetching restaurant data:', error)
    } finally {
      setLoader(false)
    }
  }

  const handleSubCategoryClick = (subCategory) => {
    setSelectedSubCategory(subCategory)
  }

  const handleCloseModal = () => {
    fetchRestaurantData()
    setSelectedSubCategory(null)
  }

  const onFormItemFormSubmit = async (categoryId, subCategoryId) => {
    await fetchRestaurantData()

    const updatedCategory = restaurantData.categoriesSubCategoriesItems.find(
      (category) => category.categoryId === categoryId,
    )

    if (updatedCategory) {
      const updatedSubCategory = updatedCategory.subcategories.find(
        (subCategory) => subCategory.subCategoryId === subCategoryId,
      )

      if (updatedSubCategory) {
        setSelectedSubCategory(updatedSubCategory)
      }
    }
  }

  const handleOpenAddCategoryModal = (category = null) => {
    setInitialCategoryData(category)
    setAddCategoryModalOpen(true)
  }

  const handleCloseAddCategoryModal = () => {
    fetchRestaurantData()
    setAddCategoryModalOpen(false)
    setInitialCategoryData(null)
  }

  const handleOpenAddSubCategoryModal = (
    subcategory = null,
    categoryId = null,
  ) => {
    setCurrentCategoryId(categoryId || subcategory.categoryId)
    setInitialSubCategoryData(subcategory)
    setAddSubCategoryModalOpen(true)
  }

  const handleCloseAddSubCategoryModal = () => {
    fetchRestaurantData()
    setAddSubCategoryModalOpen(false)
    setInitialSubCategoryData(null)
    setCurrentCategoryId(null)
  }

  const handleOpenCloseAddTaxModal = () => {
    setAddTaxModalOpen(true)
  }

  const handleCloseAddTaxModal = () => {
    setAddTaxModalOpen(false)
  }

  const handleNavigate = (route) => {
    navigate(route, { state: { restaurantId } })
  }

  return (
    <Box className="restaurant-info-container">
      <div className="title-section">
        <div className="left-section">
          <Typography variant="h4" className="title">
            Restaurant Management
          </Typography>
        </div>
        <div className="right-section">
          {userRole === ROLES.SUPER_ADMIN && (
            <div className="restaurant-actions">
              <Tooltip title="Tax Management">
                <IconButton onClick={() => handleOpenCloseAddTaxModal()}>
                  <AddCardIcon fontSize="large" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Tables Management">
                <IconButton
                  onClick={() => handleNavigate(ROUTE_STRINGS.tableManagement)}
                >
                  <TableChart fontSize="large" />
                </IconButton>
              </Tooltip>

              <Tooltip title="Order History">
                <IconButton
                  onClick={() => handleNavigate(ROUTE_STRINGS.orderHistory)}
                >
                  <Receipt fontSize="large" />
                </IconButton>
              </Tooltip>

              <Tooltip title="Sales Summary">
                <IconButton
                  onClick={() => handleNavigate(ROUTE_STRINGS.salesSummary)}
                >
                  <BarChart fontSize="large" />
                </IconButton>
              </Tooltip>
            </div>
          )}
        </div>
      </div>

      {restaurantData && (
        <>
          <Box className="restaurant-info-section">
            <Box className="restaurant-info">
              <div className="left-section">
                <Typography variant="h6" className="section-title">
                  Restaurant Info
                </Typography>
              </div>
              <div className="right-section">
                {userRole === ROLES.SUPER_ADMIN && (
                  <Typography className="restaurant-name" variant="h6">
                    {restaurantData?.restaurantName}
                  </Typography>
                )}
              </div>
            </Box>

            <Box className="info-details">
              {/* TODO :: append id in url */}
              {/* <Typography variant="body1" className="info-field">
                Restaurant ID: {restaurantData.restaurantId}
              </Typography> */}
              <Typography variant="body1" className="info-field">
                <span className="label">Restaurant Name:</span>{' '}
                {restaurantData.restaurantName}
              </Typography>
              <Typography variant="body1" className="info-field">
                <span className="label">Phone:</span>{' '}
                {restaurantData.restaurantPhone}
              </Typography>
              <Typography variant="body1" className="info-field">
                <span className="label">Email:</span>{' '}
                {restaurantData.restaurantEmail}
              </Typography>
              <Typography variant="body1" className="info-field">
                <span className="label">Admin ID:</span>{' '}
                {restaurantData.restaurantAdminId}
              </Typography>
              <Typography variant="body1" className="info-field">
                <span className="label">Start Time - End Time:</span>{' '}
                {restaurantData.restaurantStarttime} -{' '}
                {restaurantData.restaurantEndtime} (
                {restaurantData.restaurantTimezone})
              </Typography>
              <Typography variant="body1" className="info-field">
                <span className="label">Status:</span>{' '}
                {restaurantData.restaurantStatus}
              </Typography>
              {restaurantData.rating && (
                <Typography variant="body1" className="info-field">
                  <span className="label">Rating:</span> {restaurantData.rating}
                </Typography>
              )}
              {restaurantData.venuePreference && (
                <Typography variant="body1" className="info-field">
                  <span className="label">Venue Preference:</span>{' '}
                  {restaurantData.venuePreference}
                </Typography>
              )}
              {restaurantData.createdBy && (
                <Typography variant="body1" className="info-field">
                  <span className="label">Created By - Created On:</span>{' '}
                  {restaurantData.createdBy} - {restaurantData.createdOn}
                </Typography>
              )}
              {restaurantData.modifiedBy && (
                <Typography variant="body1" className="info-field">
                  <span className="label">Modified By - Modified On:</span>{' '}
                  {restaurantData.modifiedBy} - {restaurantData.modifiedOn}
                </Typography>
              )}
              {restaurantData.lastLoginTstamp && (
                <Typography variant="body1" className="info-field">
                  <span className="label">Last LogedIn:</span>{' '}
                  {restaurantData.lastLoginTstamp}
                </Typography>
              )}
              <Typography variant="body1" className="info-field">
                <span className="label">Lat-long:</span>{' '}
                {restaurantData.locLatitude} - {restaurantData.locLongitude}
              </Typography>
              <Typography variant="body1" className="info-field">
                <span className="label">Address:</span>{' '}
                {restaurantData.restaurantAddress}
              </Typography>
              <Typography variant="body1" className="info-field">
                <span className="label">City-State:</span> {restaurantData.city}
                , {restaurantData.state}, {restaurantData.countryName}
              </Typography>
              <Typography variant="body1" className="info-field">
                <span className="label">Postal Code:</span>{' '}
                {restaurantData.restaurantPostalCode}
              </Typography>
            </Box>
          </Box>

          <Box className="categories-section">
            <div className="actions-section">
              <div className="left-section">
                <Typography variant="h6" className="section-title">
                  Categories
                </Typography>
              </div>
              <div className="right-section">
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => handleOpenAddCategoryModal()}
                >
                  Add Category
                </Button>
              </div>
            </div>
            {restaurantData.categoriesSubCategoriesItems.map((category) => (
              <Box key={category.categoryId} className="category-section">
                <Box className="category-header">
                  <Typography variant="h6" className="category-title">
                    {category.categoryName}
                  </Typography>
                  <IconButton
                    onClick={() => handleOpenAddCategoryModal(category)}
                  >
                    <EditIcon style={{ color: '#ccb260' }} />
                  </IconButton>
                </Box>
                <Box className="subcategories-slider">
                  {category.subcategories.map((subcategory) => (
                    <Card
                      key={subcategory.subCategoryId}
                      className="subcategory-card"
                    >
                      <CardMedia
                        component="img"
                        height="140"
                        image={
                          subcategory.subCategoryImage
                            ? `${IMAGE_URL}${subcategory.subCategoryImage}`
                            : noImageAdded
                        }
                        alt={subcategory.subCategoryName}
                        onClick={() => handleSubCategoryClick(subcategory)}
                      />
                      <CardContent>
                        <Typography variant="h6">
                          {subcategory.subCategoryName}
                        </Typography>
                        <Typography variant="body2">
                          {subcategory.subCategoryDescription}
                        </Typography>
                      </CardContent>
                      <IconButton
                        className="edit-button"
                        onClick={() =>
                          handleOpenAddSubCategoryModal(subcategory)
                        }
                      >
                        <EditIcon style={{ color: '#ccb260' }} />
                      </IconButton>
                    </Card>
                  ))}
                  <Card
                    className="subcategory-card add-card"
                    onClick={() =>
                      handleOpenAddSubCategoryModal(null, category.categoryId)
                    }
                  >
                    <AddIcon
                      style={{
                        fontSize: 70,
                        textAlign: 'center',
                        height: '140px',
                      }}
                    />
                    <CardContent className="add-card-title">
                      <Typography variant="h6">Add Subcategory</Typography>
                    </CardContent>
                  </Card>
                </Box>
              </Box>
            ))}
          </Box>
        </>
      )}

      <ItemModal
        open={!!selectedSubCategory}
        onClose={handleCloseModal}
        subCategory={selectedSubCategory}
        onFormItemFormSubmit={onFormItemFormSubmit}
        fetchUpdatedSubCategory={async () => {
          await fetchRestaurantData()
          const updatedCategory =
            restaurantData.categoriesSubCategoriesItems.find(
              (category) =>
                category.categoryId === selectedSubCategory.categoryId,
            )

          if (updatedCategory) {
            const updatedSubCategory = updatedCategory.subcategories.find(
              (sub) => sub.subCategoryId === selectedSubCategory.subCategoryId,
            )

            setSelectedSubCategory(updatedSubCategory)
          }
        }}
      />

      <AddCategoryModal
        isOpen={isAddCategoryModalOpen}
        onClose={handleCloseAddCategoryModal}
        restaurantId={restaurantId}
        initialData={initialCategoryData}
      />
      <AddSubCategoryModal
        isOpen={isAddSubCategoryModalOpen}
        onClose={handleCloseAddSubCategoryModal}
        restaurantId={restaurantId}
        categoryId={currentCategoryId}
        initialData={initialSubCategoryData}
      />
      <AddTaxModal
        isOpen={isAddTaxModalOpen}
        onClose={handleCloseAddTaxModal}
        source={'restaurant'}
        id={restaurantId}
      />
    </Box>
  )
}

export default Loader(ViewRestaurantInfo)
