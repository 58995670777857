import './styles.scss'

import Loader from 'components/Loader'
import SalesStats from 'pages/Dashboard/Components/SalesStats'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import apiAdapter from 'Services/apiAdapter'
import { ROUTE_STRINGS, STORAGE_KEYS } from 'Utils/Constants'
import { ROLES } from 'Utils/Constants/RolesConfig'
import { getLocal, setLocal } from 'Utils/LocalStorageHandler'

const DashboardComponent = ({ setLoader }) => {
  const navigate = useNavigate()
  const userId = getLocal(STORAGE_KEYS.userId)
  const userRole = getLocal(STORAGE_KEYS.userRole)

  const [showTableMessage, setShowTableMessage] = useState(false)

  useEffect(() => {
    init()
  }, [])

  const init = async () => {
    setLoader(true)
    setShowTableMessage(true)
    try {
      const response = await apiAdapter.getUserDetailsById({ userId })
      const {
        data: { data: userData },
      } = response

      if (response.data.status) {
        if (userRole === ROLES.RESTAURANT_ADMIN) {
          const {
            data: { data: restaurantName },
          } = await apiAdapter.getRestaurantNameById(
            userData.user.restaurantId,
          )

          setLocal(STORAGE_KEYS.restaurantName, restaurantName)
        }
        setLocal(STORAGE_KEYS.userDetails, userData)
      }
    } catch (error) {
      console.error('Error occurred while logging in:', error)
    } finally {
      setLoader(false)
      setShowTableMessage(false)
    }
  }

  return (
    <div className="website-component">
      <div className="content">
        <div className="welcome-text">
          Welcome Onboard,
          <br />
          Lets Start
        </div>
      </div>
      {/* <div className="content">
        <div className="welcome-text">
          Welcome back.
          <br />
          You have an upcoming transfer of $994.51.
        </div>
        <button className="transfer-now-btn">Transfer $994.51 now</button>
        <div className="sales-separator"></div>
        <div className="sales">
          <SalesStats />
        </div>
        <div className="sales-separator"></div>

        <div className="categories">
          <div
            className="category"
            onClick={() => navigate(ROUTE_STRINGS.listRestaurants)}
          >
            <span>Restaurant Management</span>
          </div>
          <div
            className="category"
            onClick={() => navigate(ROUTE_STRINGS.venue)}
          >
            <span>Venue Management</span>
          </div>
          <div
            className="category"
            onClick={() => navigate(ROUTE_STRINGS.staff)}
          >
            <span>Staff</span>
          </div>
          <div
            className="category"
            onClick={() => navigate(ROUTE_STRINGS.salesSummary)}
          >
            <span>Reports</span>
          </div>
          <div
            className="category"
            onClick={() => navigate(ROUTE_STRINGS.tableManagement)}
          >
            <span>Table Management</span>
          </div>
          <div
            className="category"
            onClick={() => navigate(ROUTE_STRINGS.accountSettings)}
          >
            <span>Account & Settings</span>
          </div>
        </div>
      </div> */}
    </div>
  )
}

export default Loader(DashboardComponent)
