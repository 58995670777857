import './styles.scss'

import CloseIcon from '@mui/icons-material/Close'
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import Loader from 'components/Loader'
import React, { useState } from 'react'
import apiAdapter from 'Services/apiAdapter'
import swal from 'sweetalert'

const AddTaxModal = ({ isOpen, onClose, source, id, setLoader }) => {
  const [formData, setFormData] = useState({
    taxType: '',
    taxRate: '',
    taxDescription: '', // Add taxDescription
  })

  const [errors, setErrors] = useState({})

  const handleChange = (event) => {
    const { name, value } = event.target

    setFormData({ ...formData, [name]: value })
    setErrors({ ...errors, [name]: '' })
  }

  const handleBlur = (event) => {
    const { name, value } = event.target

    if (!value) {
      setErrors({ ...errors, [name]: 'Required' })
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    const newErrors = {}

    Object.keys(formData).forEach((key) => {
      if (!formData[key]) newErrors[key] = 'Required'
    })

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors)

      return
    }

    const payload = {
      taxType: formData.taxType,
      taxRate: parseFloat(formData.taxRate),
      taxDescription: formData.taxDescription,
    }

    setLoader(true)

    try {
      let response

      if (source === 'subcategory') {
        response = await apiAdapter.subcategoryTax(id, payload)
      } else if (source === 'restaurant') {
        response = await apiAdapter.restaurantTax(id, payload)
      } else if (source === 'item') {
        response = await apiAdapter.itemTax(id, payload)
      } else if (source === 'category') {
        response = await apiAdapter.categoryTax(id, payload)
      }

      if (response?.status) {
        swal('Success', 'Tax added successfully!', 'success')
        onClose()
      }
    } catch (error) {
      console.error('Error adding tax:', error)
      swal('Error', 'Failed to add tax', 'error')
    } finally {
      setLoader(false)
    }
  }

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box className="modal-container">
        <div className="modal-header">
          <Typography variant="h6">Add Tax</Typography>
          <IconButton className="close-button" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className="modal-content">
          <form onSubmit={handleSubmit}>
            <div className="form-row">
              <div className="form-group">
                <FormControl fullWidth margin="normal">
                  <InputLabel id="tax-type-label">Tax Type</InputLabel>
                  <Select
                    labelId="tax-type-label"
                    id="tax-type"
                    name="taxType"
                    value={formData.taxType}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={!!errors.taxType}
                  >
                    <MenuItem value="VAT">VAT</MenuItem>
                    <MenuItem value="Service Tax">Service Tax</MenuItem>
                    <MenuItem value="GST">GST</MenuItem>
                  </Select>
                  {errors.taxType && (
                    <Typography className="error">{errors.taxType}</Typography>
                  )}
                </FormControl>
              </div>

              <div className="form-group">
                <TextField
                  label="Tax Rate (%)"
                  name="taxRate"
                  value={formData.taxRate}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errors.taxRate}
                  helperText={errors.taxRate}
                  fullWidth
                  variant="outlined"
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group">
                <TextField
                  label="Tax Description"
                  name="taxDescription"
                  value={formData.taxDescription}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errors.taxDescription}
                  helperText={errors.taxDescription}
                  fullWidth
                  multiline
                  rows={3}
                  variant="outlined"
                  inputProps={{ maxLength: 100 }} // Limit description to 100 characters
                />
              </div>
            </div>

            <Button
              fullWidth
              variant="contained"
              color="primary"
              className="save-button"
              type="submit"
            >
              Save
            </Button>
          </form>
        </div>
      </Box>
    </Modal>
  )
}

export default Loader(AddTaxModal)
