const ROUTE_STRINGS = {
  login: '/',
  auth: '/auth',
  home: '/home',
  roles: '/roles',
  users: '/users',
  createRole: '/create-role',
  venueManagement: '/venue-management',
  item: '/item-library',
  categories: '/categories',
  modifers: '/modifers',
  staff: '/staff',
  salesSummary: '/sales-summary',
  tableManagement: '/table-management',
  qrManagement: '/qr-management',
  accountSettings: '/account-settings',
  createItem: '/create-item',
  listRestaurants: '/list-restaurants',
  onboardRestaurant: '/onboard-restaurant',
  venue: '/venue',
  restaurantInfo: '/restaurant-info',
  staff: '/staff',
  tableManagement: '/table-management',
  orderHistory: '/order-history',
  listEvents: '/list-events',
  onboardEvent: '/onboard-event',
  eventInfo: '/event-info',
}

export { ROUTE_STRINGS }
