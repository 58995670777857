import apiService from './apiService'
import { configUrl } from './configuration/index'

const version = 'v1'
const contentType = {
  json: 'application/json',
  formData: 'multipart/form-data',
}

const apiAdapter = {
  login(body, callback) {
    return apiService.post(
      `${configUrl.userMicroservice}/${version}/resources/auth/login/email`,
      null,
      body,
      callback,
    )
  },
  logout(callback) {
    return apiService.post(
      `${configUrl.userMicroservice}/${version}/resources/auth/logout`,
      null,
      callback,
    )
  },
  getUserDetailsById({ userId }, callback) {
    return apiService.get(
      `${configUrl.userMicroservice}/${version}/resources/users/${userId}`,
      null,
      callback,
    )
  },
  getAllActiveRoles(callback) {
    return apiService.get(
      `${configUrl.userMicroservice}/${version}/resources/userRoles/getAllActiveRoles`,
      null,
      callback,
    )
  },
  getUsersByRoleId({ roleId }, callback) {
    return apiService.get(
      `${configUrl.userMicroservice}/${version}/resources/userRoles/getUsersByRoleId/${roleId}`,
      null,
      callback,
    )
  },
  deleteUserById({ userId }, callback) {
    return apiService.delete(
      `${configUrl.userMicroservice}/${version}/resources/users/delete/${userId}`,
      null,
      callback,
    )
  },
  createUserRole(body, callback) {
    return apiService.post(
      `${configUrl.userMicroservice}/${version}/resources/userRoles/createUserRole`,
      null,
      body,
      callback,
    )
  },
  updateUserRole(body, callback) {
    return apiService.put(
      `${configUrl.userMicroservice}/${version}/resources/userRoles/updateUserRole`,
      null,
      body,
      callback,
    )
  },
  createUser(body, callback) {
    return apiService.post(
      `${configUrl.userMicroservice}/${version}/resources/admin/xsworld/createUser`,
      null,
      body,
      callback,
    )
  },
  updateUser(body, callback) {
    return apiService.put(
      `${configUrl.userMicroservice}/${version}/resources/users/updateUser`,
      null,
      body,
      callback,
    )
  },
  getRestaurants(callback) {
    return apiService.get(
      `${configUrl.restaurantService}/${version}/resources/xsWorld/getRestaurants`,
      null,
      callback,
    )
  },
  getRestaurantNameById(restaurantId, callback) {
    return apiService.get(
      `${configUrl.restaurantService}/${version}/resources/xsWorld/getRestaurantNameById?restaurantId=${restaurantId}`,
      null,
      callback,
    )
  },
  createRestaurant(body, callback) {
    return apiService.post(
      `${configUrl.restaurantService}/${version}/resources/xsWorld/createRestaurant`,
      null,
      body,
      callback,
    )
  },
  updateRestaurant(restaurantId, body, callback) {
    return apiService.put(
      `${configUrl.restaurantService}/${version}/resources/xsWorld/updateRestaurant/${restaurantId}`,
      null,
      body,
      callback,
    )
  },
  getRestaurantById(restaurantId, callback) {
    return apiService.get(
      `${configUrl.restaurantService}/${version}/resources/xsWorld/getRestaurantById?restaurantId=${restaurantId}`,
      null,
      callback,
    )
  },
  getAllRestaurantTables(restaurantId, callback) {
    return apiService.get(
      `${configUrl.restaurantService}/${version}/resources/xsWorld/getAllTables/${restaurantId}`,
      null,
      callback,
    )
  },
  createRestaurantTable(body, callback) {
    return apiService.post(
      `${configUrl.restaurantService}/${version}/resources/xsWorld/createRestaurantTable`,
      null,
      body,
      callback,
    )
  },
  updateRestaurantTable(restaurantTableId, body, callback) {
    return apiService.put(
      `${configUrl.restaurantService}/${version}/resources/xsWorld/updateRestaurantTable/${restaurantTableId}`,
      null,
      body,
      callback,
    )
  },
  deleteRestaurantTable(tableId, callback) {
    return apiService.delete(
      `${configUrl.restaurantService}/${version}/resources/xsWorld/deleteRestaurantTable/${tableId}`,
      null,
      callback,
    )
  },
  createCategory(body, callback) {
    return apiService.post(
      `${configUrl.restaurantService}/${version}/resources/xsWorld/createCategory`,
      null,
      body,
      callback,
    )
  },
  updateCategory(categoryId, body, callback) {
    return apiService.put(
      `${configUrl.restaurantService}/${version}/resources/xsWorld/updateCategory/${categoryId}`,
      null,
      body,
      callback,
    )
  },
  createSubCategory(body, callback) {
    return apiService.post(
      `${configUrl.restaurantService}/${version}/resources/xsWorld/createSubCategory`,
      null,
      body,
      callback,
    )
  },
  updateSubCategory(subCategoryId, body, callback) {
    return apiService.put(
      `${configUrl.restaurantService}/${version}/resources/xsWorld/updateSubCategory/${subCategoryId}`,
      null,
      body,
      callback,
    )
  },
  createItem(body, callback) {
    return apiService.post(
      `${configUrl.restaurantService}/${version}/resources/xsWorld/createItem`,
      null,
      body,
      callback,
    )
  },
  updateItem(itemId, body, callback) {
    return apiService.put(
      `${configUrl.restaurantService}/${version}/resources/xsWorld/updateItem/${itemId}`,
      null,
      body,
      callback,
    )
  },
  deleteItem(itemId, callback) {
    return apiService.delete(
      `${configUrl.restaurantService}/${version}/resources/xsWorld/deleteItem/${itemId}`,
      null,
      callback,
    )
  },
  createAddOn(body, callback) {
    return apiService.post(
      `${configUrl.restaurantService}/${version}/resources/xsWorld/createAddOn`,
      null,
      body,
      callback,
    )
  },
  updateAddOn(addOnId, body, callback) {
    return apiService.put(
      `${configUrl.restaurantService}/${version}/resources/xsWorld/updateAddOn/${addOnId}`,
      null,
      body,
      callback,
    )
  },
  deleteAddOn(addOnId, callback) {
    return apiService.delete(
      `${configUrl.restaurantService}/${version}/resources/xsWorld/deleteAddOn/${addOnId}`,
      null,
      callback,
    )
  },
  createItemQuantity(body, callback) {
    return apiService.post(
      `${configUrl.restaurantService}/${version}/resources/xsWorld/createItemQuantity`,
      null,
      body,
      callback,
    )
  },
  updateItemQuantity(itemQuantityId, body, callback) {
    return apiService.put(
      `${configUrl.restaurantService}/${version}/resources/xsWorld/updateItemQuantity/${itemQuantityId}`,
      null,
      body,
      callback,
    )
  },
  deleteItemQuantity(itemQuantityId, callback) {
    return apiService.delete(
      `${configUrl.restaurantService}/${version}/resources/xsWorld/deleteItemQuantity/${itemQuantityId}`,
      null,
      callback,
    )
  },
  createMixture(body, callback) {
    return apiService.post(
      `${configUrl.restaurantService}/${version}/resources/xsWorld/createMixture`,
      null,
      body,
      callback,
    )
  },
  updateMixture(mixtureId, body, callback) {
    return apiService.put(
      `${configUrl.restaurantService}/${version}/resources/xsWorld/updateMixture/${mixtureId}`,
      null,
      body,
      callback,
    )
  },
  deleteMixture(mixtureId, callback) {
    return apiService.delete(
      `${configUrl.restaurantService}/${version}/resources/xsWorld/deleteMixture/${mixtureId}`,
      null,
      callback,
    )
  },
  getOrderHistoryByRestaurant(
    { restaurantId, startDate, endDate, page, size },
    callback,
  ) {
    let queryParams = `restaurantId=${restaurantId}&page=${page}&size=${size}`

    // Only add startDate and endDate if they are not null
    if (startDate) {
      queryParams += `&startDate=${startDate}`
    }
    if (endDate) {
      queryParams += `&endDate=${endDate}`
    }

    return apiService.get(
      `${configUrl.orderMicroservice}/${version}/resources/orders/getOrderHistoryByRestaurant?${queryParams}`,
      null,
      callback,
    )
  },
  // getRestaurantItems(body, callback) {
  //   return apiService.post(`${configUrl.restaurantService}/api/restaurant/restaurant/restaurant-items`, body, callback)
  // },
  // Event Microservice API's
  getEvents(callback) {
    return apiService.get(
      `${configUrl.eventService}/${version}/resources/xsWorld/getEvents`,
      null,
      callback,
    )
  },
  getEventById(eventId, callback) {
    return apiService.get(
      `${configUrl.eventService}/${version}/resources/xsWorld/getEventById?eventId=${eventId}`,
      null,
      callback,
    )
  },
  createEvent(body, callback) {
    return apiService.post(
      `${configUrl.eventService}/${version}/resources/xsWorld/createRestaurant`,
      null,
      body,
      callback,
    )
  },
  updateEvent(eventId, body, callback) {
    return apiService.put(
      `${configUrl.eventService}/${version}/resources/xsWorld/updateEvent/${eventId}`,
      null,
      body,
      callback,
    )
  },
  deleteEvent(eventId, callback) {
    return apiService.delete(
      `${configUrl.eventService}/${version}/resources/xsWorld/deleteEvent/${eventId}`,
      null,
      callback,
    )
  },
  subcategoryTax(subCategoryId, body, callback) {
    return apiService.post(
      `${configUrl.restaurantService}/${version}/resources/tax/subcategory/${subCategoryId}`,
      null,
      body,
      callback,
    )
  },
  restaurantTax(restaurantId, body, callback) {
    return apiService.post(
      `${configUrl.restaurantService}/${version}/resources/tax/restaurant/${restaurantId}`,
      null,
      body,
      callback,
    )
  },
  itemTax(itemId, body, callback) {
    return apiService.post(
      `${configUrl.restaurantService}/${version}/resources/tax/item/${itemId}`,
      null,
      body,
      callback,
    )
  },
  categoryTax(categoryId, body, callback) {
    return apiService.post(
      `${configUrl.restaurantService}/${version}/resources/tax/category/${categoryId}`,
      null,
      body,
      callback,
    )
  },
}

export default apiAdapter
